import React, { Component, Suspense } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import { CSpinner } from '@coreui/react-pro'
import './scss/style.scss'
import PageReload from './services/redux-store/PageReload'
import { UserAuthContextProvider } from './context/UserAuthContext'
import PhoneSignUp from './auth/PhoneSignUp'
var token = localStorage.getItem('token')
// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
//const Login = React.lazy(() => import('./auth/PhoneSignUp'))
const ForgotPassword = React.lazy(() => import('./auth/forgotPassword'))
const UpdatePassword = React.lazy(() => import('./auth/updatePassword'))

class App extends Component {
  render() {
    return (
      <UserAuthContextProvider>
        <HashRouter>
          <PageReload />
          <Suspense fallback={<CSpinner color="primary" />}>
            <Switch>
              {!!token === false ? (
                <Route
                  exact
                  path="/"
                  name="Login Page"
                  render={(props) => <PhoneSignUp {...props} />}
                />
              ) : null}
              <Route
                exact
                path="/forgotPassword"
                name="Forgot Password"
                render={(props) => <ForgotPassword />}
              />
              <Route
                exact
                path="/updatePassword/:token"
                name="Update Password"
                render={(props) => <UpdatePassword {...props} />}
              />

              <Route path="/" name="Home" render={(props) => <DefaultLayout {...props} />} />
            </Switch>
          </Suspense>
        </HashRouter>
      </UserAuthContextProvider>
    )
  }
}

export default App
