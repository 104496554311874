import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

export const multipleAPICalls = (data) => async (dispatch) => {
  var Token = localStorage.getItem('jwtToken')
  let reqObj = {
    auth_token: Token,
  }

  const checkAuthResult = await axios.post(
    process.env.REACT_APP_BASE_URL + '/sauth0/api/v1/fb/checkauth',
    reqObj,
  )
  try {
    const getUserResult = await axios.get(
      process.env.REACT_APP_BASE_URL +
        `/sauth0/api/v1/user?id=${checkAuthResult?.data?.message?.uid}`,
    )
    Object.assign(getUserResult?.data, { menu_permissions1: {} })
    // eslint-disable-next-line array-callback-return
    Object.keys(getUserResult?.data?.permissions).map((data, idx) => {
      if (!!data.match(/menuitem_/gi) === true) {
        Object.assign(getUserResult?.data, {
          menu_permissions1: {
            ...getUserResult?.data?.menu_permissions1,
            [Object.keys(getUserResult?.data?.permissions)[idx]]:
              getUserResult?.data?.permissions[Object.keys(getUserResult?.data?.permissions)[idx]],
          },
        })
      }
    })
    console.log('getUserResult?.data?.menu_permissions', getUserResult?.data?.menu_permissions1)
    localStorage.setItem('menu_permissions', JSON.stringify(getUserResult?.data?.menu_permissions1))
    const api_results = { user: getUserResult?.data }
    return api_results
  } catch (err) {
    console.log(err)
    // localStorage.clear()
    // console.clear()
    // document.location.href = '/'
  }
}
const initial_state = {
  sidebarShow: true,
  asideShow: false,
  theme: 'default',
  userData: [],
}
const appSlice = createSlice({
  name: 'app/data',
  initialState: initial_state,
  userData: [],
  reducers: {
    updateUserData: (state, action) => {
      return { ...state, userData: action.payload }
    },
    updateSidebarShow: (state, action) => {
      return { ...state, sidebarShow: !state.sidebarShow }
    },
  },
})
export const { updateUserData, updateSidebarShow } = appSlice.actions
export default appSlice.reducer
